import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(
          fields: {
            slug: {
              regex: "/snippets\/meta/"
          }
        }
      ) {
          frontmatter {
            siteName
            description
          }
        }
      }
    `
  )

  return {
    metadata: markdownRemark?.frontmatter
  }
}

export default useSiteMetadata
