import React from 'react'
import { Link } from 'gatsby'

import useFooter from 'hooks/useFooterQuery'

const FooterLink = ({ link }) => {
  if (link.url.startsWith('http')) {
    return (
      <a
        href={link.url}
        className='u-color-grey e-h6'
        target='_blank'
        rel='noopener noreferrer'
      >
        {link.label}
      </a>
    )
  }

  return (
    <Link
      to={link.url}
      className='u-color-grey e-h6'
    >
      {link.label}
    </Link>
  )
}

const Footer = () => {
  const { blurb, links } = useFooter()

  return (
    <footer className='c-footer u-color-grey u-pad-b'>
      <div className='o-layout'>
        <div className='o-layout__item u-width-2/3@md u-width-3/4@lg'>
          <p className='e-h6 c-footer__blurb u-mar-b-none@md'>
            &copy;
            {' '}
            {new Date().getFullYear()}
            {' '}
            {blurb || ''}
          </p>
        </div>
        {(links && links.length) &&
          <div className='o-layout__item u-width-1/3@md u-width-1/4@lg'>
            <ul className='o-layout o-cleanlist o-list-inline u-fr@md'>
              {links.map((link, linkIndex) => {
                return (
                  <li key={linkIndex} className='o-layout__item u-width-auto'>
                    <FooterLink link={link} />
                  </li>
                )
              })}
            </ul>
          </div>}
      </div>
    </footer>
  )
}

export default Footer
