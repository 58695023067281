import React from 'react'
import PropTypes from 'prop-types'

export const Content = ({ content, className }) => (
  <div className={`o-rte ${className || ''}`}>
    <p>{content}</p>
  </div>
)

Content.propTypes = {
  content: PropTypes.node.isRequired,
  className: PropTypes.string
}

export const HTMLContent = ({ content, className }) => (
  <div
    className={`${className ? `${className} o-rte` : 'o-rte'}`}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

HTMLContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string
}
