import React from 'react'
import { Fade } from 'react-reveal'

const Reveal = ({ children, ...props }) => (
  <Fade
    duration={props.duration || 420}
    distance={props.distance || '24px'}
    fraction={props.fraction || 0.2}
    {...props}
  >
    {children}
  </Fade>
)

export default Reveal
