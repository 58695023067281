import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import classNames from 'classnames'

import Reveal from 'Reveal'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'

const BackToTop = ({ className }) => {
  return (
    <div className={classNames('o-container u-ta-c', className)}>
      <Reveal bottom>
        <div className='c-back-to-top__cta'>
          <AnchorLink href='#top'>
            <ArrowSVG className='c-back-to-top__icon' />
            Back to top
          </AnchorLink>
        </div>
      </Reveal>
    </div>
  )
}

export default BackToTop
