import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import useSiteMetadata from 'hooks/useSiteMetadataQuery'
import useMetadata from 'hooks/useMetadataQuery'

const SEO = ({ ...data }) => {
  const { siteMetadata } = useSiteMetadata()
  const { metadata } = useMetadata()

  /* Merge siteMetadata with metadata, merge that with props */
  const {
    title,
    siteName,
    description,
    image,
    siteUrl,
    slug
  } = Object.assign({}, siteMetadata, metadata, data)

  /* Generate a full URL to the content & image path */
  const url = slug ? (siteUrl + slug) : siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
      titleTemplate={`%s - ${siteName}`}
      defaultTitle={siteName}
    >
      <title>{title}</title>

      {/* Resource Hints */}
      <link rel='preconnect dns-prefetch' href='https://ucarecdn.com' />
      <link rel='preconnect dns-prefetch' href='https://www.google-analytics.com' />

      <meta name='description' content={description} />

      {/* Open Graph Tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title ? `${title} - ${siteName}` : siteName} />
      <meta property='og:description' content={description} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={image} />
      <meta name='twitter:card' content='summary' />

      {/* Google Search Console */}
      <meta name='google-site-verification' content='eJT47rxCX-t48167TWQzbEPjRdprhNV2pkH97kBS0qs' />

    </Helmet>
  )
}

SEO.propTypes = {
  data: PropTypes.object
}

export default SEO
