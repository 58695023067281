import React from 'react'
import classNames from 'classnames'

const Section = ({ className, children, ...props }) => (
  <section
    className={classNames('c-page-section', className)}
    {...props}
  >
    <div className='c-page-section__inner'>
      {children}
    </div>
  </section>
)

export default Section
