import React from 'react'

import SEO from 'SEO'
import Footer from 'Footer'

const Layout = ({ hideFooter, children }) => (
  <main>
    <SEO />
    {children}
    {
      !hideFooter &&
        <Footer />
    }
  </main>
)

export default Layout
