import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import ArrowSVG from 'assets/img/svg/arrow-right.svg'

const ArrowLink = ({ to, children, className, svgClassname, reverse, state }) => (
  <Link
    to={to}
    className={classNames('c-arrow-link', {
      [className]: className,
      'c-arrow-link--reverse': reverse
    })}
    state={state}
  >
    <span className='c-arrow-link__text'>
      {children}
    </span>
    <span className={classNames('c-arrow-link__svg', {
      [svgClassname]: svgClassname,
      'c-arrow-link__svg--reverse': reverse
    })}
    >
      <ArrowSVG aria-hidden />
    </span>
  </Link>
)

export default ArrowLink
