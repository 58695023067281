/**
 * CSS Min Width breakpoints
 * Same values as in `src/assets/scss/10_settings/_grid-settings.scss`
 */
export default {
  sm: 568,
  md: 738,
  lg: 1060,
  xl: 1312
}
