import React from 'react'

import Image from 'Image'

/**
 * Convert sizes into usable format, using the
 * Image.breakpoint helper function.
 *
 * This prevents us having to import the Image component
 * in to many of our components.
 *
 * 'sizes' should be defined in components as an object:
 * { xxl: '100vw', default: 'calc(100vw - 50px)' }
 */
const createSizes = (sizes) => {
  // sense check
  if (!sizes) return

  return Object.keys(sizes).map((breakpoint) => {
    if (breakpoint !== 'default') {
      return `${Image.breakpoint(breakpoint.toString())} ${sizes[breakpoint]}`
    } else {
      return sizes[breakpoint]
    }
  })
}

const Figure = ({ className, wrapperClassName, image, mobileImage, alt, width, sizes, lazyload, children }) => (
  <figure className={`${className || ''}`}>
    <Image
      image={image}
      mobileImage={mobileImage}
      alt={alt}
      width={width}
      sizes={createSizes(sizes)}
      lazyload={lazyload}
      className={wrapperClassName}
    />
    {children}
  </figure>
)

export default Figure
